import '../css/NavBar.css';
import * as React from 'react';
import { useState } from 'react';
import {Link} from 'react-router-dom';
import CompanyLogo from '../images/logo.png';


function NavBar() {

    // change the burger bar
    const [burger_class, setBurgerClass] = useState("burger-bar unclicked")
    const [menu_class, setMenuClass] = useState("navbar-links hidden")
    const [isMenuClicked, setIsMenuClicked] = useState(false)

    // toggle burger bar
    const updateMenu = () => {
        if (!isMenuClicked) {
            setBurgerClass("burger-bar clicked")
            setMenuClass("navbar-links visible")
        } else {
            setBurgerClass("burger-bar unclicked")
            setMenuClass("navbar-links hidden")
        }

        setIsMenuClicked(!isMenuClicked)
    }

    return (
        <nav className='navbar'>
            {/* Logo location goes here*/}
            <Link to='/' className='logo'><img src={CompanyLogo} className='companyLogo' alt='GA Web Design Logo' id='companyLogo'/></Link>
            <div className='burger-menu' onClick={updateMenu}>
                <div className={burger_class}></div>
                <div className={burger_class}></div>
                <div className={burger_class}></div>
            </div>
            <div className={menu_class}>
                <ul>
                    <li><Link to='/' className='home' aria-label='Return to the home page to learn about GA Web Design'>Home</Link></li>
                    <li><Link to='/pricing' className='pricing' aria-label='Learn more about our the cost for our services'>Price</Link></li>
                    <li><Link to='/payment' className='payment' aria-label='Already have a bill? Access our payment options here'>Pay Bill</Link></li>
                </ul>
                
            </div>
        </nav>
    )
}
 
export default NavBar;