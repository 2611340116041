import * as React from 'react';


function Home(){
    return(
        <div className='page'>
            <div>Home</div>
        </div>
    )
}

export default Home;