import './css/App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import AnimatedRoutes from './components/AnimatedRoutes';
import {BrowserRouter as Router} from "react-router-dom";



/**
 * App Routes all pages for the web application together nav bar is loaded with every page
 * @returns 
 */
function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <AnimatedRoutes />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
