import React from 'react';
import Home from '../pages/home';
import Pricing from '../pages/pricing';
import Payment from '../pages/payment';
import { Route, Routes, useLocation } from "react-router-dom";
import {AnimatePresence} from 'framer-motion';

const AnimatedRoutes = () => {
    const location = useLocation();
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' exact element={<Home />} />
                <Route path='/pricing' exact element={<Pricing />} />
                <Route path='/payment' exact element={<Payment />} />
            </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;
